<template>
  <div class="home">
    <transition name="clipCircle">
      <Welcome v-if="welcome" @skip="skip"></Welcome>
    </transition>
    <HomeNav v-if="!welcome"></HomeNav>
  </div>
</template>

<script>
import Welcome from "./Welcome.vue";
import HomeNav from "./HomeNav.vue";
export default {
  name: "home",
  components: {
    Welcome,
    HomeNav,
  },
  data() {
    return {
      welcome: true,
    };
  },
  methods: {
    skip() {
      this.welcome = false;
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.home {
  height: 100vh;
  overflow: hidden;
}
</style>
