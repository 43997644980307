<template>
  <div :class="['nav', animate && 'animate', entry]">
    <section class="nav-left" @click="skipPage('wood')">
      <transition
        :leave-active-class="entry == 'wood' ? 'fadeOut animated' : ''"
      >
        <div class="content" v-if="flag">
          <img class="leaf" src="./imgs/leaf-4.png" alt="" />
          <img class="xinmu" src="./imgs/xinmu.png" alt="" />
          <div class="picture">
            <img src="./imgs/p1.png" alt="" />
            <img src="./imgs/p2.png" alt="" />
            <img src="./imgs/p3.png" alt="" />
            <img src="./imgs/p4.png" alt="" />
            <img src="./imgs/p5.png" alt="" />
          </div>
          <div class="notice">
            <!-- <h3>公告</h3> -->
            <p>团队人数<span class="bold">100+</span>,均来自行业</p>
            <p>一线公司,美术视觉设计经验</p>
            <p>丰富,提供全流程设计、</p>
            <p>人才管理等服务。</p>
            <!-- <p>产学一体综合团队</p>
            <p>提供游戏职业教育、校企合作服务</p>
            <p>学员12万，100%推荐就业</p> -->
          </div>
        </div>
      </transition>
    </section>
    <section class="nav-right" @click="skipPage('leaf')">
      <transition
        :leave-active-class="entry == 'leaf' ? 'fadeOut animated' : ''"
      >
        <div class="content" v-if="flag">
          <img class="leaf" src="./imgs/leaf-3.png" alt="" />
          <img class="yezi" src="./imgs/yezi.png" alt="" />
          <img class="card" src="./imgs/card.png" alt="" />
          <div class="decorate">
            <p><span class="bold">产教一体</span>综合团队</p>
            <p>提供游戏职业教育、</p>
            <p>校企合作服务</p>
            <p>学员 <span class="green">12</span> 万</p>
            <p><span class="green">100%</span> 推荐就业</p>
          </div>
          <div class="copy-right">
            <p>
              Copyright© 2021 LEAFSTUDIO.CN. All Rights Reserved.
              <span class="link" @click.stop="skipBeian"
                >沪ICP备2021030376号-1</span
              >
              <img
                @click.stop="skipLicense"
                class="license"
                src="./imgs/license.jpg"
                alt=""
              />
            </p>
            <p>上海市杨浦区大桥街道长阳路1687号长阳创谷1号楼1单元410室</p>
          </div>
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animate: false,
      entry: "",
      flag: true,
    };
  },
  methods: {
    skipPage(name) {
      this.entry = name;
      this.$nextTick(() => {
        this.flag = false;
        setTimeout(() => {
          this.$router.push({ name: name == "wood" ? "NewWood" : "Leaf" });
        }, 1000);
      });
    },
    skipBeian() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
    skipLicense() {
      window.open(
        "https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020220110142121000008802356-SAIC_SHOW_310000-2c984b428a0650a9018a08fd4716045f826&signData=MEYCIQD8mtRqLU0Nykvkj17pq6+/sHi8FaD+7Ksbiiv3OKZYpAIhAN4AngbQMO2/OAjih80nwbc/n7hHUsxdI174DPaYP6/q",
        "_blank"
      );
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 1500);
  },
};
</script>

<style lang="scss" scoped>
.nav {
  cursor: pointer;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &-left {
    height: 100%;

    background: #fff;
    background: url("../wood/imgs/bg@2x.png") center/100% auto repeat;
    width: 50%;
    position: relative;
    transition: transform 0.7s;

    .leaf {
      width: remPc(575);
      position: absolute;
      top: 20vh;
      right: remPc(200);
      transform: translateX(-200%);
    }
    .xinmu {
      width: remPc(252);
      position: absolute;
      top: 46vh;
      right: remPc(246);
      opacity: 0;
    }
    .picture {
      position: absolute;
      left: remPc(200);
      bottom: remPc(100);
      width: remPc(360);
      height: remPc(300);
      img {
        transform-origin: center 150%;
        width: remPc(360);
        transform: rotate(-180deg);
        position: absolute;
        &:nth-of-type(1) {
          z-index: 5;
        }
        &:nth-of-type(2) {
          z-index: 4;
        }
        &:nth-of-type(3) {
          z-index: 3;
        }
        &:nth-of-type(4) {
          z-index: 2;
        }
        &:nth-of-type(5) {
          z-index: 1;
        }
      }
    }
    .notice {
      color: #666;
      position: absolute;
      bottom: remPc(192);
      left: remPc(560);
      h3 {
        font-size: remPc(16);
        color: #8a53ec;
        font-weight: 600;
        margin-bottom: 5px;
        transform: translateY(30px);
        opacity: 0;
      }
      p {
        font-size: remPc(18);
        letter-spacing: 2px;
        line-height: remPc(35);
        transform: translateY(30px);
        opacity: 0;
        font-weight: bold;
        // color: #000;
        .bold {
          font-size: remPc(26);
        }
      }
    }
  }
  &-right {
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
    transition: transform 0.7s;

    width: 50%;
    position: relative;
    background: url("../leaf/imgs/bg@2x.png") center/100% auto repeat;
    .leaf {
      width: remPc(901);
      position: absolute;
      top: remPc(-86);
      right: remPc(-190);
      z-index: 1;
      transform: rotate(60deg);
      transform-origin: 200% bottom;
    }
    .yezi {
      width: remPc(200);
      position: absolute;
      top: remPc(555);
      left: remPc(90);
      z-index: 3;
      opacity: 0;
    }
    .card {
      height: 44vh;
      position: absolute;
      top: remPc(164);
      transform: translateY(-150%);
      left: remPc(60);
      z-index: 2;
    }
    .decorate {
      width: remPc(480);
      position: absolute;
      top: remPc(555);
      right: remPc(-50);
      transform: rotate(-15deg);
      transition: transform 1s;
      padding-left: remPc(40);

      p {
        color: rgba(255, 255, 255, 0.9);
        font-size: remPc(18);
        line-height: remPc(40);
        font-weight: 500;
        transform-origin: 200% center;
        transform: rotate(-95deg);
        &:nth-of-type(1) {
          position: relative;
          &::after {
            content: "";
            height: 2px;
            width: 0;
            background: #8a53ec;
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
        .bold {
          font-size: remPc(25);
          font-weight: 500;
          padding-right: 5px;
        }
        .green {
          font-size: remPc(26);
          color: #58bc17;
          font-weight: bold;
        }
      }
    }
    .copy-right {
      color: #696969;
      position: absolute;
      bottom: remPc(22);
      right: remPc(28);
      font-size: remPc(16);
      opacity: 0;
      border: 5px solid #242424;
      border-radius: 7px;
      padding: remPc(10) remPc(30);
      background: #292929;

      p {
        text-align: right;
        line-height: remPc(25);
        .link {
          &:hover {
            text-decoration: underline;
          }
        }
        .license {
          // width: 15px;
          height: 14px;
          margin-left: 4px;
        }
      }
    }
  }
  &.animate {
    .nav-left {
      &:hover {
        transform: scale(1.02);
      }
      // &:hover {
      //   .leaf {
      //     transform: translateX(20px);
      //   }
      //   .xinmu {
      //     transform: translateX(20px);
      //   }
      //   .picture {
      //     transform: scale(1.02);
      //   }
      //   .notice {
      //     transform: translateY(-10px);
      //   }
      // }
      .leaf {
        transform: translateX(0);
        transition: transform 0.7s;
      }
      .xinmu {
        opacity: 1;
        transition: opacity 1.5s 1.5s, transform 0.7s;
      }
      .picture {
        transition: transform 0.7s;

        img {
          transition: transform 2s;
          &:nth-of-type(1) {
            transform: rotate(0deg);
            transition-delay: 0s;
          }
          &:nth-of-type(2) {
            transform: rotate(-10deg);
            transition-delay: 0.2s;
            transition-timing-function: ease-out;
          }
          &:nth-of-type(3) {
            transform: rotate(-35deg);
            transition-delay: 0.4s;
          }
          &:nth-of-type(4) {
            transform: rotate(-50deg);
            transition-delay: 0.6s;
          }
          &:nth-of-type(5) {
            transform: rotate(-60deg);
            transition-delay: 0.8s;
          }
        }
      }
      .notice {
        color: #666;
        position: absolute;
        transition: transform 0.4s;

        h3 {
          font-size: remPc(16);
          color: #8a53ec;
          font-weight: 600;
          margin-bottom: 5px;
          opacity: 1;
          transform: translateY(0px);
          transition: transform 1s, opacity 1s;
          transition-delay: 0.8s;
        }
        p {
          opacity: 1;
          transform: translateY(0px);
          transition: transform 1s, opacity 1s;
          transition-delay: 1s;
        }
      }
    }
    .nav-right {
      &:hover {
        transform: scale(1.02);
        // .leaf {
        //   transform: rotate(1deg);
        // }
        // .yezi {
        //   transform: scale(1.1);
        // }
        // .card {
        //   transform: scale(1.05);
        // }
        // .decorate {
        //   transform: rotate(-20deg);
        //   transform-origin: center;
        //   transition: transform 1s;
        //   p {
        //     color: #8a53ec;
        //   }
        // }
      }
      .leaf {
        transform: rotate(0deg);
        transition: transform 1.5s;
      }
      .yezi {
        opacity: 1;
        transition: opacity 3s 1.5s, transform 0.5s;
      }
      .card {
        transform: translateY(0);
        transition: transform 0.7s;
      }
      .decorate {
        p {
          transform: rotate(0deg);
          transition: transform 1s;

          &:nth-of-type(1) {
            transition-delay: 0.8s;
            &::after {
              width: 100%;
              transition: width 1s 2.5s;
            }
          }
          &:nth-of-type(2) {
            transition-delay: 1s;
          }
          &:nth-of-type(3) {
            transition-delay: 1.2s;
          }
          &:nth-of-type(4) {
            transition-delay: 1.3s;
          }
          &:nth-of-type(5) {
            transition-delay: 1.45s;
          }
        }
      }
      .copy-right {
        opacity: 1;
        transition: opacity 3s 1.5s;
      }
    }
  }
  &.wood {
    .nav-left {
      width: 100%;
      transition: width 0.7s;
    }
    .nav-right {
      width: 0;
      transition: width 0.7s;
    }
  }
  &.leaf {
    .nav-left {
      width: 0;
      transition: width 1s;
    }
    .nav-right {
      width: 100%;
      transition: width 1s;
    }
  }
}
</style>
